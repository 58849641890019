import type * as React from 'react';

import * as Styled from './progress-bar.styles';
const { textPositionings, textVariants } = Styled;

export type ProgressBarTextPositioning = keyof typeof textPositionings;
export type ProgressBarTextVariant = keyof typeof textVariants;
export type ProgressBarVariant = 'determinate' | 'indeterminate';

export type ProgressBarProps = {
  /** Change the component to a different HTML element */
  as?: React.ElementType;
  /** Add custom CSS */
  className?: string;
  /** Ref forwarding */
  forwardRef?: React.Ref<HTMLDivElement>;
  /** Add an optional label text */
  labelText?: string;
  /** The progress of the process already completed */
  progress?: number;
  /** Set a different color for the progress bar */
  progressBarColor?: string;
  /** Turn the label with the progress value and optional text off */
  showLabel?: boolean;
  /** Positioning of the label text */
  textPositioning?: ProgressBarTextPositioning;
  /** Text color of the label text */
  textVariant?: ProgressBarTextVariant;
  /** The variant of the progress bar */
  variant?: ProgressBarVariant;
} & React.HTMLAttributes<HTMLDivElement>;
