import { FormattedMessage } from 'react-intl';

import { messages } from './messages';
import {
  PasswordStrengthIndicatorText,
  PasswordStrengthIndicator,
} from './password-strength-meter.styled';
import strength from './strength';
import type { IndicatorTextType, PasswordStrengthMeterType } from './types';

const calculateStrength = (password: string): number => {
  if (typeof password !== 'string') return 0;
  if (password.length < 8) return 0;

  return strength(password);
};

const IndicatorText = ({
  password,
  strength,
  error,
}: IndicatorTextType): JSX.Element | string => {
  const PASSWORD_MINIMUM_LENGTH = 8;
  const NO_CONTENT = '\u00A0';
  if (error) return error;
  if (password === '') return NO_CONTENT;
  if (password.length < PASSWORD_MINIMUM_LENGTH)
    return <FormattedMessage {...messages.passwordTooShort} />;

  return <FormattedMessage {...messages[`strength${strength}`]} />;
};

export const PasswordStrengthMeter = ({
  password,
  error,
}: PasswordStrengthMeterType): JSX.Element => {
  const strength = calculateStrength(password);

  return (
    <div>
      <PasswordStrengthIndicator
        data-qa="strength-indicator"
        strength={strength}
      />
      <PasswordStrengthIndicatorText
        data-qa="strength-indicator-text"
        error={Boolean(error)}
      >
        <IndicatorText error={error} password={password} strength={strength} />
      </PasswordStrengthIndicatorText>
    </div>
  );
};
