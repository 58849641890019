import type { Xinglet } from '@xing-com/crate-xinglet';

import { RegistrationForm } from './registration-form';
import type { RegistrationFormProps } from './types';

export default class RegistrationFormXinglet implements Xinglet {
  public getComponent() {
    return (props: RegistrationFormProps): JSX.Element => (
      <RegistrationForm {...props} />
    );
  }
}
