// import { JSON_HEADERS } from './constants';
import { useWindowOptions } from '@xing-com/crate-login-utils';

import type { Values } from './types';

export const isValidRecaptchaResponse = (token: string): boolean =>
  token.length > 0;

export const sanitizeValue = (value: string): string =>
  value.trim().replace(/\s{2,}/, ' ');

export const useValuesToPayload = (
  {
    firstName,
    lastName,
    email,
    password,
    parsedCvId,
    tandcCheck,
    signupChannel,
    gRecaptchaResponse,
    skipEmailDomainCheck,
    checkSecondaryFields,
  }: Values,
  emailOnly: boolean
): Record<string, unknown> => {
  const registrationOptions = useWindowOptions({}, 'registration');

  const miniregPayload = {
    email: sanitizeValue(email),
    password: password,
    tandc_check: tandcCheck,
    signup_channel: signupChannel,
    skip_email_domain_check: skipEmailDomainCheck,
    check_secondary_fields: checkSecondaryFields,
    email_check_type: 'strict',
    ...(parsedCvId && { parsed_cv_id: sanitizeValue(parsedCvId) }),
    ...(emailOnly
      ? { email_only: true }
      : {
          first_name: sanitizeValue(firstName),
          last_name: sanitizeValue(lastName),
        }),
  };

  return Object.assign(
    {},
    registrationOptions,
    { signup_minireg: miniregPayload },
    isValidRecaptchaResponse(gRecaptchaResponse)
      ? { 'g-recaptcha-response': gRecaptchaResponse }
      : {}
  );
};
