import type { StrengthRule } from './types';

const ruleset: StrengthRule[] = [
  {
    name: 'minlength',
    re: /.{12,}/,
    weight: 1,
  },
  {
    name: 'lowercase',
    re: /[a-z]/,
    weight: 1,
  },
  {
    name: 'uppercase',
    re: /[A-Z]/,
    weight: 1,
  },
  {
    name: 'numbers',
    re: /\d/,
    weight: 1,
  },
  {
    name: 'symbols',
    re: /[^A-Za-z0-9]/,
    weight: 1,
  },
  {
    name: 'uniqueness',
    re: /^(.)\1*$/,
    weight: -1,
  },
  {
    name: 'year',
    re: /(19|20)\d{2}(?!\d)/,
    weight: -1,
  },
];

const strength = (password: string): number => {
  const strength = ruleset.reduce((acc, rule) => {
    if (rule.re.test(password)) return (acc += rule.weight);
    return acc;
  }, 0);

  return strength > 0 ? strength : 1;
};

export default strength;
