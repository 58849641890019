import type { Messages } from './types';

const messages: Messages = {
  headline: {
    id: 'WELCOME_REGISTRATION_HEADLINE',
    defaultMessage: 'Registrieren Sie sich jetzt!',
  },
  firstNameLabel: {
    id: 'WELCOME_REGISTRATION_FIRSTNAME_LABEL',
    defaultMessage: 'Vorname',
  },
  lastNameLabel: {
    id: 'WELCOME_REGISTRATION_LASTNAME_LABEL',
    defaultMessage: 'Nachname',
  },
  emailLabel: {
    id: 'WELCOME_REGISTRATION_EMAIL_LABEL',
    defaultMessage: 'E-Mail',
  },
  passwordLabel: {
    id: 'WELCOME_REGISTRATION_PASSWORD_LABEL',
    defaultMessage: 'Passwort',
  },
  tandcLabel: {
    id: 'WELCOME_REGISTRATION_TANDC_LABEL',
    defaultMessage: 'AGB',
  },
  dataprotectionLabel: {
    id: 'WELCOME_REGISTRATION_DATAPROTECTION_LABEL',
    defaultMessage: 'Datenschutzbedingungen',
  },
  tandcDescription: {
    id: 'WELCOME_REGISTRATION_TANDC_DESCRIPTION',
    defaultMessage:
      'Mit der Registrierung akzeptiere ich die {tandc} und habe die {dataprotection} zur Kenntnis genommen.',
  },
  dataprotectionLink: {
    id: 'WELCOME_REGISTRATION_DATAPROTECTION_LINK',
    defaultMessage: 'https://privacy.xing.com/de/datenschutzerklaerung',
  },
  submitLabel: {
    id: 'WELCOME_REGISTRATION_SUBMIT_LABEL',
    defaultMessage: 'Registrieren',
  },
  validateRequestError: {
    id: 'WELCOME_SIGNUP_FORM_VALIDATE_REQUEST_ERROR',
    defaultMessage:
      'Leider ist ein Fehler aufgetreten. Versuchen Sie es bitte noch einmal.',
  },
  submitRequestError: {
    id: 'WELCOME_SIGNUP_FORM_SUBMIT_REQUEST_ERROR',
    defaultMessage:
      'Leider ist ein Fehler aufgetreten. Versuchen Sie es bitte noch einmal.',
  },
  firstNameRequiredError: {
    id: 'WELCOME_LOGIN_FORM_FIRSTNAME_REQUIRED_ERROR',
    defaultMessage: 'Geben Sie ihren Vornamen ein.',
  },
  lastNameRequiredError: {
    id: 'WELCOME_LOGIN_FORM_LASTNAME_REQUIRED_ERROR',
    defaultMessage: 'Geben Sie ihren Nachnamen ein.',
  },
  emailRequiredError: {
    id: 'WELCOME_LOGIN_FORM_EMAIL_REQUIRED_ERROR',
    defaultMessage: 'Geben Sie ihre E-Mail Adresse ein.',
  },
  passwordRequiredError: {
    id: 'WELCOME_LOGIN_FORM_PASSWORD_REQUIRED_ERROR',
    defaultMessage: 'Geben Sie ihr Passwort ein.',
  },
  recaptchaHeadline: {
    id: 'WELCOME_REGISTRATION_RECAPTCHA_HEADLINE',
    defaultMessage: 'Fast geschafft!',
  },
  hidePasswordText: {
    id: 'ACCOUNT_FORM_HIDE_PASSWORD_TEXT',
    defaultMessage: 'Passwort verstecken',
  },
  showPasswordText: {
    id: 'ACCOUNT_FORM_SHOW_PASSWORD_TEXT',
    defaultMessage: 'Passwort anzeigen',
  },
  recaptchaParagraph: {
    id: 'WELCOME_REGISTRATION_RECAPTCHA_PARAGRAPH',
    defaultMessage:
      'Um Sie und andere vor Spam zu schützen, möchten wir verifizieren, dass Sie eine echte Person sind.',
  },
  skipEmailDomainCheck: {
    id: 'WELCOME_REGISTRATION_IGNORE_SOFT_EMAIL_ERRORS_LABEL',
    defaultMessage: 'Ja, {email} ist meine korrekte E-Mail-Adresse.',
  },
  emailDomainError: {
    id: 'WELCOME_REGISTRATION_SOFT_EMAIL_ERROR',
    defaultMessage:
      'Prüf bitte nochmal, ob Deine E-Mail-Adresse wirklich korrekt ist.',
  },
  secondaryEmailError: {
    id: 'WELCOME_REGISTRATION_SECONDARY_EMAIL_ERROR',
    defaultMessage:
      'Diese E-Mail-Adresse ist bereits bei einem anderen Konto als Kontakt-Adresse hinterlegt. Willst du wirklich einen weiteren Account erstellen?',
  },
  skipSecondaryEmailCheck: {
    id: 'WELCOME_REGISTRATION_SKIP_SECONDARY_EMAIL_CHECK',
    defaultMessage: 'Skip secondary email check {email}',
  },
  separator: {
    id: 'LOGIN_SEPARATOR',
    defaultMessage: 'oder',
  },
  registrationHeadline: {
    id: 'LOGIN_REGISTRATION_HEADLINE',
    defaultMessage: 'Sign up',
  },
  registrationSubheadline: {
    id: 'LOGIN_REGISTRATION_SUBHEADLINE',
    defaultMessage: 'Already have an account? {signin}',
  },
  loginHeadline: {
    id: 'LOGIN_REGISTRATION_SIGNIN_HEADLINE',
    defaultMessage: 'Login',
  },
};

export default messages;
