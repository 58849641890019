import { useLocation } from '@reach/router';
import type { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import { getMainHost, getExtraParams } from '@xing-com/crate-login-utils';
import { useHost } from '@xing-com/crate-xinglet';
import { IconApple } from '@xing-com/icons';

import { IconGoogle } from './assets/IconGoogle';
import { GoogleButton, AppleButton } from './third-parties.styled';
import type { ThirdPartyButton } from './types';

export const ContinueWithGoogle: FC<ThirdPartyButton> = ({
  className,
  signupChannel,
  caller,
  onClick,
}) => {
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const host = useHost();
  const mainHostUrl = getMainHost(host.getHostname());

  const extraParams = getExtraParams(urlParams);

  return (
    <GoogleButton
      variant="secondary"
      size="medium"
      icon={IconGoogle}
      href={`${mainHostUrl}/signup/oauth?caller=${
        caller ?? 'signup_page'
      }&signup_channel=${signupChannel ? signupChannel : ''}${extraParams}`}
      onClick={onClick}
      data-qa="continue-with-google"
      className={className}
    >
      <FormattedMessage
        id="WELCOME_STARTPAGE_PROCEED_WITH_GOOGLE_BUTTON"
        defaultMessage="Continue with Google"
      />
    </GoogleButton>
  );
};

export const ContinueWithApple: FC<ThirdPartyButton> = ({
  className,
  signupChannel,
  caller,
  onClick,
}) => {
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const extraParams = getExtraParams(urlParams);
  const host = useHost();
  const mainHostUrl = getMainHost(host.getHostname());

  return (
    <AppleButton
      variant="secondary"
      size="medium"
      icon={IconApple}
      data-qa="continue-with-apple"
      href={`${mainHostUrl}/signup/oauth/apple?caller=${
        caller ?? 'signup_page'
      }&signup_channel=${signupChannel ? signupChannel : ''}${extraParams}`}
      onClick={onClick}
      className={className}
    >
      <FormattedMessage
        id="WELCOME_STARTPAGE_PROCEED_WITH_APPLE_BUTTON"
        defaultMessage="Continue with Apple"
      />
    </AppleButton>
  );
};
