import styled, { css, keyframes } from 'styled-components';

import {
  cornerRadiusM,
  motionEasingEnter,
  motionEasingLeave,
  motionEasingStandard,
  motionTimeL,
  motionTimeM,
  scale030,
  scale100,
  spaceS,
  xdlColorControlFillTertiary,
  xdlColorSelected,
  xdlColorText,
  xdlColorTextSecondary,
} from '@xing-com/tokens';

import type {
  ProgressBarVariant,
  ProgressBarTextPositioning,
  ProgressBarTextVariant,
} from './progress-bar.types';

type ProgressIndicatorTransientProps = {
  $progress?: number;
  $variant?: ProgressBarVariant;
  $progressBarColor?: string;
};

type TextContainerTransientProps = {
  $textPositioning: ProgressBarTextPositioning;
  $textVariant: ProgressBarTextVariant;
};

// *** Animations ***
const indeterminateAnimation = keyframes`
  0% {
    animation-timing-function: ${motionEasingEnter};
    transform: translate(-50%, 0) scale(0, 1);
  }
  38.5% {
    animation-timing-function: ${motionEasingLeave};
    transform: translate(50%, 0) scale(1, 1);
  }
  76.9% {
    animation-timing-function: ${motionEasingStandard};
    transform: translate(150%, 0) scale(0, 1);
  }
  100% {
    transform: translate(150%, 0) scale(0, 1);
  }
`;

// *** Text Positioning ***
export const textPositionings = {
  left: css`
    text-align: left;
  `,
  center: css`
    text-align: center;
  `,
  right: css`
    text-align: right;
  `,
};

// *** Text Variant ***
export const textVariants = {
  default: css`
    color: ${xdlColorText};
  `,
  lighter: css`
    color: ${xdlColorTextSecondary};
  `,
};

// *** Components ***
export const Container = styled.div`
  flex-direction: column;
`;

export const Tracker = styled.div`
  background-color: ${xdlColorControlFillTertiary};
  border-radius: ${cornerRadiusM};
  height: ${scale030};
  overflow: hidden;
`;

export const ProgressIndicator = styled.div<ProgressIndicatorTransientProps>`
  background-color: ${xdlColorSelected};
  border-radius: ${cornerRadiusM};
  height: ${scale030};
  ${({ $progressBarColor }) =>
    $progressBarColor &&
    css`
      background-color: ${$progressBarColor};
    `};
  ${({ $variant, $progress }) =>
    $variant === 'determinate' && $progress
      ? css`
          width: ${$progress}%;
        `
      : css`
          width: 0;
        `};

  ${({ $variant }) =>
    $variant === 'indeterminate' &&
    css`
      animation-duration: calc(${motionTimeL} * 2 + ${motionTimeM});
      animation-iteration-count: infinite;
      animation-name: ${indeterminateAnimation};
      width: 50%;
    `};
`;

export const TextContainer = styled.div<TextContainerTransientProps>`
  height: ${scale100};
  margin-top: ${spaceS};

  ${({ $textVariant }) => $textVariant && textVariants[$textVariant]};
  ${({ $textPositioning }) =>
    $textPositioning && textPositionings[$textPositioning]};
`;
