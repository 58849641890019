import * as React from 'react';

import { BodyCopy } from '@xing-com/typography';

import * as Styled from './progress-bar.styles';
import type { ProgressBarProps } from './progress-bar.types';

/** Avoid progress being lower than 0% or beyond 100% */
const clampProgress = (progress: number): number =>
  Math.min(Math.max(0, progress), 100);

export const ProgressBar = React.forwardRef<HTMLDivElement, ProgressBarProps>(
  (
    {
      className,
      labelText,
      progress,
      progressBarColor,
      showLabel = true,
      textPositioning = 'left',
      textVariant = 'default',
      variant = 'determinate',
      ...props
    },
    forwardedRef
  ): JSX.Element => {
    const sanitizedProgress = progress && clampProgress(progress);

    /** Without a progress value the variant will be automatically 'indeterminate' */
    if (progress === undefined) {
      variant = 'indeterminate';
    }

    return (
      <Styled.Container
        className={className}
        data-xds="ProgressBar"
        ref={forwardedRef}
        {...props}
      >
        <Styled.Tracker>
          <Styled.ProgressIndicator
            $progress={sanitizedProgress}
            $progressBarColor={progressBarColor}
            $variant={variant}
          />
        </Styled.Tracker>
        {variant === 'determinate' && showLabel && (
          <Styled.TextContainer
            $textPositioning={textPositioning}
            $textVariant={textVariant}
          >
            <BodyCopy size="medium">
              {sanitizedProgress}%{labelText && ` ${labelText}`}
            </BodyCopy>
          </Styled.TextContainer>
        )}
      </Styled.Container>
    );
  }
);

ProgressBar.displayName = 'ProgressBar';
