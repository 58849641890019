import { useLayoutEffect } from 'react';

import type { RecaptchaProps } from '../../types';
import { StyledRecaptchaContainer } from './recaptcha.styled';

declare global {
  interface Window {
    submitRecaptcha: (token: string) => void;
  }
}

export const Recaptcha: React.FC<RecaptchaProps> = ({
  googleSiteKey,
  language,
  setValues,
}): JSX.Element => {
  useLayoutEffect(() => {
    const script = document.createElement('script');
    script.src = `https://www.google.com/recaptcha/enterprise.js?hl=${language}`;
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);
  }, []);

  if (typeof window !== 'undefined') {
    window.submitRecaptcha = (token) => {
      setValues((values) => ({
        ...values,
        gRecaptchaResponse: token,
      }));
    };
  }

  return (
    <StyledRecaptchaContainer
      data-testid={'recaptcha-component'}
      className={'g-recaptcha'}
      data-sitekey={googleSiteKey}
      data-action={'signup'}
      data-callback={'submitRecaptcha'}
    />
  );
};
