import { useRef, useState } from 'react';
import type { MutableRefObject } from 'react';
import { FormattedMessage } from 'react-intl';

import type { InteractionTrackingEvent } from '@xing-com/crate-communication-tracking';
import { useTracking } from '@xing-com/crate-communication-tracking';
import { IconUpload } from '@xing-com/icons';
import { Headline, BodyCopy } from '@xing-com/typography';

import type { TrackingType, RegisterViaCvSectionType } from '../../types';
import {
  ERROR_MESSAGES,
  MAX_ALLOWED_CV_SIZE_IN_MB,
  PROP_CHANNEL,
  ACCEPTED_FILE_FORMATS,
} from './constants';
import messages from './messages';
import {
  Container,
  StyledButton,
  HelperText,
  StyledStatusBanner,
  UploadInput,
  StyledContentBanner,
  ResultsContainer,
  StyledIconFilePDF,
  StyledProgressBar,
  Content,
  FileName,
} from './register-via-cv-section.styled';
import useGetParsedCvData from './use-get-parsed-cv-data';
import useUploadFile from './use-upload-file';

export const convertToMB = (size: number): string =>
  (size / 1024 / 1024).toFixed(2);

export const RegisterViaCvSection = ({
  handleParsedCvDataReceived,
  handleResetCvUpload,
  cvUploadConfig,
}: RegisterViaCvSectionType): JSX.Element => {
  const { track } = useTracking<InteractionTrackingEvent<TrackingType>>();

  const [file, setFile] = useState<File | null>(null);
  const fileUploadInput: MutableRefObject<HTMLInputElement | null> =
    useRef(null);

  const [fileUploadError, setFileUploadError] = useState<string | null>(null);
  const [fileUploadLoading, setFileUploadLoading] = useState<boolean>(false);
  const [fileUploadSuccess, setFileUploadSuccess] = useState<boolean>(false);

  const { uploadFile } = useUploadFile();
  const { getParsedCvData } = useGetParsedCvData();

  const trackError = (): void => {
    track({
      type: 'interaction',
      event: 'PropTrackAction',
      PropTrackAction: 'losp_register_via_cv_upload_error',
      PropChannel: PROP_CHANNEL,
    });
  };

  const onFileSelected = async (selectedFile: File): Promise<void> => {
    try {
      const uploadId = await uploadFile(selectedFile);
      if (!uploadId) {
        throw Error('uploadId could not be fetched');
      }

      const parsedCvData = await getParsedCvData(
        uploadId,
        cvUploadConfig.source,
        cvUploadConfig.flowIdentifier
      );
      if (!parsedCvData) {
        throw Error('parsedCvData could not be fetched');
      }

      const cvDataHandled = await handleParsedCvDataReceived(parsedCvData);

      track({
        type: 'interaction',
        event: 'PropTrackAction',
        PropChannel: PROP_CHANNEL,
        PropTrackAction: 'losp_register_via_cv_upload_success',
        PropContextDimension1: `losp_resgister_via_cv_prefill_${
          cvDataHandled ? 'success' : 'error'
        }`,
      });
      setFileUploadLoading(false);
      setFileUploadError(null);
      setFileUploadSuccess(true);
    } catch (e) {
      trackError();

      setFileUploadLoading(false);
      setFileUploadError(ERROR_MESSAGES.common);
      setFileUploadSuccess(false);
      setFile(null);
      if (fileUploadInput.current) {
        fileUploadInput.current.value = '';
      }
    }
  };

  const resetFileUpload = (): void => {
    track({
      type: 'interaction',
      event: 'PropTrackAction',
      PropChannel: PROP_CHANNEL,
      PropTrackAction: 'losp_register_via_cv_remove_click',
    });

    setFileUploadLoading(false);
    setFileUploadError(null);
    setFileUploadSuccess(false);
    setFile(null);
    if (fileUploadInput.current) {
      fileUploadInput.current.value = '';
    }

    handleResetCvUpload && handleResetCvUpload();
  };

  const handleErrorBannerClose = (): void => setFileUploadError(null);

  const handleFileSelect = async (
    event: React.ChangeEvent<HTMLInputElement>
  ): Promise<void> => {
    setFileUploadLoading(true);
    setFileUploadError(null);
    setFileUploadSuccess(false);
    setFile(null);

    try {
      const files = event.target.files;

      if (files && files.length >= 1) {
        const file: File = Array.from(files).pop() as File;
        const fileSize = Number(convertToMB(file.size));
        const fileExtension = `.${(file.name || '').split('.').pop()}`;
        const isFileSizeError = fileSize > MAX_ALLOWED_CV_SIZE_IN_MB;
        const isFileFormatError =
          !fileExtension || !ACCEPTED_FILE_FORMATS.includes(fileExtension);

        if (isFileSizeError || isFileFormatError) {
          track({
            type: 'interaction',
            event: 'PropTrackAction',
            PropChannel: PROP_CHANNEL,
            PropTrackAction: `losp_register_via_cv_upload_error_${
              isFileSizeError ? 'filesize' : 'format'
            }`,
          });

          setFileUploadLoading(false);
          setFileUploadError(ERROR_MESSAGES.badFile);
        } else {
          setFileUploadLoading(false);
          setFile(file);
          await onFileSelected(file);
        }
      }
    } catch (e) {
      trackError();

      setFileUploadLoading(false);
      setFileUploadError(ERROR_MESSAGES.common);
      setFile(null);
      if (fileUploadInput.current) {
        fileUploadInput.current.value = '';
      }
    }
  };

  const selectFile = (event: Event): void => {
    if (event) {
      event.preventDefault();
    }

    track({
      type: 'interaction',
      event: 'PropTrackAction',
      PropChannel: PROP_CHANNEL,
      PropTrackAction: 'losp_register_via_cv_upload_click',
    });

    fileUploadInput.current && fileUploadInput.current.click();
  };

  return (
    <>
      <Container>
        <Headline size="medium">
          <FormattedMessage {...messages.headlineDefault} />
        </Headline>
        <Content>
          <BodyCopy size="small" noMargin>
            <FormattedMessage {...messages.descriptionDefault} />
          </BodyCopy>
        </Content>
        <UploadInput
          type="file"
          tabIndex={-1}
          ref={fileUploadInput}
          name="cv-upload-input"
          multiple={false}
          accept={ACCEPTED_FILE_FORMATS.join(',')}
          onChange={handleFileSelect}
        />
        {!fileUploadSuccess && !fileUploadLoading ? (
          <>
            <StyledButton
              variant="secondary"
              size="small"
              icon={IconUpload}
              // @ts-expect-error FIXME: SC6
              onClick={selectFile}
            >
              <FormattedMessage {...messages.buttonLabel} />
            </StyledButton>
            <HelperText size="xsmall" noMargin>
              <FormattedMessage {...messages.fileInfoLabel} />
            </HelperText>
          </>
        ) : (
          <StyledContentBanner
            display="inline"
            noPadding
            // @ts-expect-error FIXME: SC6
            handleOnClose={fileUploadSuccess ? resetFileUpload : null}
          >
            <StyledIconFilePDF />
            <ResultsContainer>
              <FileName fontWeight="bold" noMargin>
                {file ? file.name : ''}
              </FileName>
              {fileUploadLoading ? (
                <StyledProgressBar variant="indeterminate" />
              ) : file ? (
                <BodyCopy size="small" noMargin>
                  {`${convertToMB(file.size)} MB`}
                </BodyCopy>
              ) : null}
            </ResultsContainer>
          </StyledContentBanner>
        )}
      </Container>
      {fileUploadError ? (
        <StyledStatusBanner
          variant="error"
          display="inline"
          handleOnClose={handleErrorBannerClose}
        >
          <FormattedMessage
            {...messages[fileUploadError]}
            values={{ fileName: file ? file.name : '' }}
          />
        </StyledStatusBanner>
      ) : null}
    </>
  );
};
