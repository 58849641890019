import type { PropsWithChildren } from 'react';

import { Styled } from './divider.styles';

export const Divider: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <Styled.Separator>
      <Styled.SeparatorLine />
      <Styled.SeparatorText size="medium" sizeConfined="medium">
        {children}
      </Styled.SeparatorText>
      <Styled.SeparatorLine />
    </Styled.Separator>
  );
};
