import { useMutation } from '@apollo/client';

import { UploadApplication } from '@xing-com/crate-common-graphql-types';
import { useFetch } from '@xing-com/crate-xinglet';

import { CvUploadRequestDocument } from './upload-request-mutation.gql-types';

const uploadFileToUrl = async (
  url: string,
  authToken: string,
  file: File,
  fetch: (input: RequestInfo | URL, init?: RequestInit) => Promise<Response>
): Promise<boolean> => {
  const response = await fetch(url, {
    method: 'PATCH',
    headers: {
      Authorization: `Bearer ${authToken}`,
      'Upload-Offset': '0',
      'Tus-Resumable': '1.0.0',
      'Content-Type': 'application/offset+octet-stream',
    },
    body: file,
  });

  return !!response.ok;
};

type UploadFileHookReturnType = {
  uploadFile: (file: File) => Promise<null>;
};

const useUploadFile = (): UploadFileHookReturnType => {
  const [uploadRequestMutation] = useMutation(CvUploadRequestDocument);
  const fetch = useFetch();

  const uploadFile = async (file: File): Promise<null> => {
    if (!file) return null;

    const { data } = await uploadRequestMutation({
      variables: {
        application: UploadApplication.CvUpload,
        fileSize: file.size,
        fileName: file.name,
        fileType: file.type,
      },
    });

    if (data && data.uploadRequest && data.uploadRequest.success) {
      const { id, url, authToken } = data.uploadRequest.success;

      if (url && authToken) {
        const fileIsUploaded = await uploadFileToUrl(
          url,
          authToken,
          file,
          fetch
        );

        if (fileIsUploaded) {
          return id;
        }
      }
    }

    return null;
  };

  return { uploadFile };
};

export default useUploadFile;
