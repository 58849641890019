/* eslint-disable */
// @ts-nocheck
import * as Types from '@xing-com/crate-common-graphql-types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type CreateCvUploadParsedCvMutationMutationVariables = Types.Exact<{
  uploadId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  source?: Types.InputMaybe<Types.Scalars['GlobalID']['input']>;
  flowIdentifier?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;

export type CreateCvUploadParsedCvMutationMutation = {
  __typename?: 'Mutation';
  createCvUploadParsedCv?:
    | { __typename?: 'CvUploadError' }
    | {
        __typename?: 'CvUploadParsedCv';
        id: string;
        textkernelPayload?: {
          __typename?: 'CvUploadParsedCvTextkernelPayload';
          personal?: {
            __typename?: 'CvUploadPersonal';
            firstName?: string | null;
            lastName?: string | null;
            emails?: Array<{
              __typename?: 'CvUploadEmail';
              email?: string | null;
            } | null> | null;
          } | null;
        } | null;
      }
    | null;
};

export const CreateCvUploadParsedCvMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateCvUploadParsedCvMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'uploadId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'source' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'GlobalID' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'flowIdentifier' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createCvUploadParsedCv' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'uploadId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'uploadId' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'source' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'source' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'flowIdentifier' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'flowIdentifier' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'CvUploadParsedCv' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'textkernelPayload' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'personal' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'firstName' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'lastName' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'emails' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'email',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateCvUploadParsedCvMutationMutation,
  CreateCvUploadParsedCvMutationMutationVariables
>;
