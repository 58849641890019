import { useLocation } from '@reach/router';

const REGISTRATION_PARAMS_WHITELIST = ['register_redirect_to', 'dest_url'];
const LOGIN_PARAMS_WHITELIST = ['dest_url', 'set_language', 'level'];

type WindowOptions = {
  [key: string]: string;
};

type WindowType = 'login' | 'registration';

export const useWindowOptions = (
  windowOptions: WindowOptions = {},
  type: WindowType
): WindowOptions => {
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);

  const PARAMS_WHITELIST =
    type === 'login' ? LOGIN_PARAMS_WHITELIST : REGISTRATION_PARAMS_WHITELIST;

  return PARAMS_WHITELIST.reduce((acc, name) => {
    if (urlParams.has(name)) acc[name] = urlParams.get(name) || '';

    return acc;
  }, windowOptions);
};
