import type { MessageDescriptor } from 'react-intl';

export const messages: Record<string, MessageDescriptor> = {
  passwordTooShort: {
    id: 'ACCOUNT_FORMS_PASSWORD_STRENGTH_LESS_THAN',
    defaultMessage: 'Password must have at least 8 characters',
  },
  strength0: {
    id: 'ACCOUNT_FORMS_PASSWORD_STRENGTH_0',
    defaultMessage: 'This password is very weak',
  },
  strength1: {
    id: 'ACCOUNT_FORMS_PASSWORD_STRENGTH_1',
    defaultMessage: 'Weak password',
  },
  strength2: {
    id: 'ACCOUNT_FORMS_PASSWORD_STRENGTH_2',
    defaultMessage: 'Good password',
  },
  strength3: {
    id: 'ACCOUNT_FORMS_PASSWORD_STRENGTH_3',
    defaultMessage: 'Strong password',
  },
  strength4: {
    id: 'ACCOUNT_FORMS_PASSWORD_STRENGTH_4',
    defaultMessage: 'Very strong password',
  },
  strength5: {
    id: 'ACCOUNT_FORMS_PASSWORD_STRENGTH_5',
    defaultMessage: 'Excellent password',
  },
};
