import styled from 'styled-components';

import { Button } from '@xing-com/button';
import { BodyCopy } from '@xing-com/crate-login-layout';
import { mediaConfined } from '@xing-com/layout-tokens';
import { spaceL, spaceXL, scale080, spaceS } from '@xing-com/tokens';

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media ${mediaConfined} {
    max-width: 380px;
  }
`;

const FakeInput = styled.input`
  height: 0;
  opacity: 0;
  position: absolute;
  z-index: -1;
`;

const HideSmallerThanTablet = styled.div`
  display: none;

  @media ${mediaConfined} {
    display: inline-flex;
  }
`;

const InputActions = styled.div`
  margin-top: ${spaceXL};
  margin-bottom: ${spaceXL};
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  display: flex;
`;

const SubmitButton = styled(Button)`
  width: 100%;
`;

const TextCenter = styled.div`
  margin-top: ${spaceL};
  text-align: center;
`;

const InputWrapper = styled.div`
  margin-bottom: ${spaceXL};
`;

const Label = styled.label`
  font-size: ${scale080};
  vertical-align: super;
  padding-left: 8px;
  cursor: pointer;
`;

const HiddenWrapper = styled.div`
  display: none;
`;

const CheckboxWrapper = styled.div`
  display: flex;

  & label {
    margin-left: ${spaceS};
  }
`;

const TandC = styled(BodyCopy)`
  text-align: center;
  width: 100%;
`;

export {
  FormContainer,
  FakeInput,
  HideSmallerThanTablet,
  InputActions,
  InputWrapper,
  Label,
  SubmitButton,
  TextCenter,
  HiddenWrapper,
  CheckboxWrapper,
  TandC,
};
