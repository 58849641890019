import type { ErrorMessages, AcceptedFileFormats } from '../../types';

export const ERROR_MESSAGES: ErrorMessages = {
  badFile: 'badFileErrorLabel',
  common: 'commonErrorLabel',
};

export const ACCEPTED_FILE_FORMATS: AcceptedFileFormats = [
  '.pdf',
  '.docx',
  '.pages',
];

export const MAX_ALLOWED_CV_SIZE_IN_MB = 100;

export const PROP_CHANNEL = 'wbm/Welcome';
