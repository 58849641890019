import styled from 'styled-components';

import { ContentBanner, StatusBanner } from '@xing-com/banner';
import { Button } from '@xing-com/button';
import { IconFilePDF } from '@xing-com/icons';
import { ProgressBar } from '@xing-com/progress-bar';
import {
  xdlColorBackground,
  spaceXL,
  spaceL,
  spaceM,
  cornerRadiusL,
  scale100,
  xdlColorBorderSoft,
  xdlColorTextTertiary,
  spaceXXL,
  scale160,
} from '@xing-com/tokens';
import { BodyCopy } from '@xing-com/typography';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: ${xdlColorBackground};
  margin-bottom: ${spaceXXL};
  padding: ${spaceL} ${spaceL};
  border-radius: ${cornerRadiusL};
  border: 2px solid ${xdlColorBorderSoft};
`;

export const ImageContainer = styled.div`
  margin-right: ${scale100};
  margin-bottom: 0;
`;

export const StyledButton = styled(Button)`
  margin-bottom: ${spaceM};
`;

export const HelperText = styled(BodyCopy)`
  color: ${xdlColorTextTertiary};
`;

export const StyledStatusBanner = styled(StatusBanner)`
  margin-bottom: ${spaceXL};

  & div {
    justify-content: flex-start;
  }
`;

export const StyledContentBanner = styled(ContentBanner)`
  display: flex;
  align-items: flex-start;
  margin-top: ${spaceXL};
  background: transparent;
`;

export const StyledIconFilePDF = styled(IconFilePDF)`
  height: ${scale160};
  margin-right: ${spaceL};
`;

export const UploadInput = styled.input`
  position: absolute;
  top: 0;
  left: -1000em;
  height: 0;
  width: 0;
  opacity: 0;
`;

export const ResultsContainer = styled.div`
  width: 100%;
`;

export const StyledProgressBar = styled(ProgressBar)`
  margin-top: ${spaceM};
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: ${spaceL};
`;

export const FileName = styled(BodyCopy)`
  width: 90%;
`;
