import type { FC } from 'react';

import { FormMessageBanner } from './form-message.styled';
import type { FormMessageType } from './types';

export const FormMessage: FC<FormMessageType> = ({ notice, error }) => {
  const message = error || notice;

  if (!message) return null;

  const variant = error ? 'error' : 'info';
  const bannerProps = {
    display: 'inline',
    variant,
  };

  return (
    // @ts-expect-error FIXME: SC6
    <FormMessageBanner {...bannerProps}>
      {typeof message === 'string' ? (
        <span dangerouslySetInnerHTML={{ __html: message }} /> // eslint-disable-line
      ) : (
        message
      )}
    </FormMessageBanner>
  );
};
