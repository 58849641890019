import { useState } from 'react';
import type { FC } from 'react';

import { Button } from '@xing-com/button';
import { IconHide, IconShow } from '@xing-com/icons';
import { FormField } from '@xing-com/text-field';

import type { PasswordInputType } from './types';

export const PasswordInput: FC<PasswordInputType> = ({
  name = 'password',
  onBlur,
  onChange,
  label,
  required,
  placeholder,
  value,
  ...props
}) => {
  const [isVisible, setIsVisible] = useState(false);

  const Icon = isVisible ? IconHide : IconShow;

  return (
    // @ts-expect-error FIXME: SC6
    <FormField
      data-qa="password-input"
      size="medium"
      name={name}
      onBlur={onBlur}
      onChange={onChange}
      label={label}
      placeholder={placeholder}
      action={
        <Button
          size="small"
          icon={Icon}
          onMouseDown={() => setIsVisible(!isVisible)}
        />
      }
      required={required}
      value={value}
      type={isVisible ? 'text' : 'password'}
      {...props}
    />
  );
};
